import { Button, MenuItem, Paper, Typography, useTheme, Box, Modal, Alert, TextField, Switch, Grid, AlertTitle, Autocomplete, InputAdornment } from "@mui/material"
import axios from "axios"
import React, { useCallback, useEffect, useState } from 'react'
import { findAllSafeZonesRequest } from "../../api/controllers/safe-zone-controller"
import CustomDataGrid, { IslemlerRender } from "../../components/CustomDataGrid"
import { useSnackbar } from 'notistack';
import { meRequest } from "../../api/controllers/account-controller"
import CustomDialog from "../../components/CustomDialog"
import { SearchRounded, Try } from "@mui/icons-material"
import { findAllVehiclesForAdminRequest, updateLicenseDateRequest } from "../../api/controllers/admin-controller"
import { deleteByIdRequest, findByIdRequest, setLockRequest, updateVehicleRequest } from "../../api/controllers/vehicle-controller"
import { DatePicker } from "@mui/lab"
import moment from "moment"

const Page = () => {
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar();

    const [search, setSearch] = useState("")


    
    
    // added
    const [inspectionGivenDate, setInspectionGivenDate] = useState("")
    const [inspectionExpireDate, setInspectionExpireDate] = useState("")
    
    const [mandatoryInsuranceStartDate, setMandatoryInsuranceStartDate] = useState("")
    const [mandatoryInsuranceExpireDate, setMandatoryInsuranceExpireDate] = useState("")
    
    const [comprehensiveInsuranceStartDate, setComprehensiveInsuranceStartDate ] = useState("")
    const [comprehensiveInsuranceExpireDate, setComprehensiveInsuranceExpireDate] = useState("")
    
    const [tireInstallationDate, setTireInstallationDate  ] = useState("")
    const [tireReplacementDistance, setTireReplacementDistance ] = useState("")
    // added

    const [filterDate, setFilterDate] = useState(null)
    const [safeZones, setSafeZones] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [vehiclesBackUp, setVehiclesBackUp] = useState([])
    const [pageable, setPageable] = useState({ page: 0, size: 100, total: 0 });
    const [loading, setLoading] = useState(true);
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [editVehicleForm, setEditVehicleForm] = useState({
        id: null,
        fuelType: "",
        idleAlarmSecond: null,
        imei: "",
        licensePlate: "",
        licenseDate: "",
        maintenanceDistance: null,
        maxSpeed: null,
        vehicleName: "",
        safeZone: {},
        inspectionGivenDate: "",
        totalDistanceKm: null,
        inspectionExpireDate: "",
        mandatoryInsuranceStartDate: "",
        mandatoryInsuranceExpireDate: "",
        comprehensiveInsuranceStartDate: "",
        comprehensiveInsuranceExpireDate: "",
        tireInstallationDate: "",
        tireReplacementDistance: null
    })
    const [immobilizer, setImmobilizer] = useState(false)

    const columns = [
        {
            field: 'vehicleName',
            headerName: 'Araç Adı',
            minWidth: 130,
            flex: 1,

        },
        {
            field: 'imei',
            headerName: 'IMEI',
            minWidth: 130,
            flex: 1,
        },
        {
            field: 'licensePlate',
            headerName: 'Plaka',
            minWidth: 130,
            flex: 1,
        },
        {
            field: 'licenseDate',
            headerName: 'Lisans Tarihi',
            minWidth: 130,
            flex: 1,
            renderCell: ({ value }) => value ? moment(value).format("LL") : "-"
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value }) => <IslemlerRender
                menuItems={
                    [
                        <MenuItem key={"edit"} onClick={() => {
                            let id = Number((value))
                            handleEditOpenModal(id)
                        }}>Düzenle</MenuItem>,
                        /*  <MenuItem key={"edit"} onClick={() => {
                             let id = Number((value))
                             handleEditOpenModal(id)
                         }}>Düzenle</MenuItem>,
                         <MenuItem key={"delete"} onClick={async () => {
                             const confirm = await window.confirm("Silmek istediğinize emin misiniz?")
                             if (confirm) {
                                 let id = Number((value))
                                 try {
                                     await deleteByIdRequest({ id })
                                 } catch (error) {
                                     console.log('error', error)
                                 }
                                 fetchVehicles()
                             }
                         }
                         }>Sil</MenuItem> */
                    ]
                }
            />
        },
    ]

    const fetchVehicles = async () => {
        setLoading(true);
        try {
            let res = await findAllVehiclesForAdminRequest(pageable)
            if (res?.data) {
                setVehicles(res.data.content);
                setVehiclesBackUp(res.data.content);
                setPageable({ ...pageable, total: res.data.totalElements });
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }

    const handleEditOpenModal = async (id) => {
        try {
            let res = await findByIdRequest({ id })
            if (res) {
                setEditVehicleForm(res.data)
                setEditModalOpen(true)
            }
        } catch (error) { console.log('error', error) }
    }

    const handleUpdate = async (id) => {
        try {
            let res = await updateVehicleRequest(editVehicleForm)
            if (res) {
                fetchVehicles()
            }
        } catch (error) {
            console.log(error)
        }
        try {
            let rex = await updateLicenseDateRequest({ id: editVehicleForm?.id, licenseDate: editVehicleForm?.licenseDate })
            if (rex) {
                fetchVehicles()
            }
        } catch (error) { }
        setEditModalOpen(false)
    }

    const fetchSafeZones = async () => {
        let res = await findAllSafeZonesRequest()
        if (res?.data) {
            setSafeZones(res?.data)
        }
    }

    useEffect(() => {
        fetchVehicles()
        fetchSafeZones()

    }, [])

    useEffect(() => {
        fetchVehicles();
    }, [pageable.page, pageable.size])

    useEffect(() => {
        if (search) {
            let abc = vehiclesBackUp.filter((v) => v.vehicleName.toUpperCase().includes(search.toUpperCase()) || v.imei.toUpperCase().includes(search.toUpperCase()) || v.licensePlate.toUpperCase().includes(search.toUpperCase()))
            setVehicles(abc)
        } else {
            fetchVehicles()
        }
    }, [search])

    useEffect(() => {

        if (filterDate) {
            let abc = vehiclesBackUp.filter((v) => new Date(v.licenseDate) < new Date(filterDate))
            setVehicles(abc)
        } else {
            fetchVehicles()
        }
    }, [filterDate])


    return (
        <Box p={3}>
            <Paper
                elevation={0}
                sx={{
                    display: "flex",
                    padding: theme.spacing(2),
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: "row" },
                    background: "#FBE9E7",
                    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)"
                }}
            >
                <Typography variant="h5" color="primary" fontWeight={500}>Araçlar</Typography>
                {

                    <div
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: "row" }
                        }}
                    >
                        {/* <Button onClick={() => {setCreateModalOpen(true) }} disableElevation sx={{
                            margin: theme.spacing(1),
                            width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" }
                        }} variant="contained" color="primary">Yeni Araç Ekle</Button> */}
                    </div>
                }


            </Paper>
            <Paper
                elevation={0}
                sx={{
                    padding: theme.spacing(2),
                    marginTop: theme.spacing(2),
                    minHeight: "50vh",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: theme.spacing(2) }}>
                    <TextField
                        variant="standard"
                        placeholder="Araçlar içinde arayın"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchRounded />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ width: "100%", maxWidth: "220px", }}
                        value={search}
                        onChange={(e) => { setSearch(e.target.value) }}
                    />
                    <Box >
                        <DatePicker
                            fullWidth
                            label="Lisans Tarihine Göre Filtrele"
                            value={filterDate}
                            onChange={(newValue) => { setFilterDate(newValue) }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                </Box>
                <CustomDataGrid
                    columns={columns}
                    data={vehicles}
                    loading={loading}
                    pageable={pageable}
                    setPageable={setPageable}
                />
            </Paper>

            <CustomDialog
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                title="Düzenle"
                onSubmit={async (e) => {
                    e.preventDefault();
                    console.log('editVehicleForm', editVehicleForm)
                    try {
                        let res = await handleUpdate(editVehicleForm?.id)
                        if (res) {
                            enqueueSnackbar('Günceleme Başarılı', { variant: "success" });
                        }
                    } catch (error) { }
                }}
                buttons={[
                    {
                        text: "Kaydet",
                        props: {
                            variant: "contained",
                            type: "submit",
                        }
                    }
                ]}
            >
                <Grid container spacing={3} >
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Araç Adı"
                            fullWidth
                            required={true}
                            value={editVehicleForm?.vehicleName}
                            onChange={(e) => { setEditVehicleForm({ ...editVehicleForm, vehicleName: e.target.value }) }}

                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Plaka"
                            fullWidth
                            required={true}
                            value={editVehicleForm?.licensePlate}
                            onChange={(e) => { setEditVehicleForm({ ...editVehicleForm, licensePlate: e.target.value }) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Imei"
                            fullWidth
                            required={true}
                            value={editVehicleForm?.imei}
                            onChange={(e) => { setEditVehicleForm({ ...editVehicleForm, imei: e.target.value }) }}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Max Hız(0 ise uyarı devre dışı)"
                            fullWidth
                            type="number"
                            required={true}
                            value={editVehicleForm?.maxSpeed}
                            onChange={(e) => { setEditVehicleForm({ ...editVehicleForm, maxSpeed: e.target.valueAsNumber }) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Yakıt Türü"
                            fullWidth
                            select
                            value={editVehicleForm?.fuelType}
                            onChange={(e) => { setEditVehicleForm({ ...editVehicleForm, fuelType: e.target.value }) }}
                        >
                            <MenuItem value="GASOLINE">Benzin</MenuItem>
                            <MenuItem value="DIESEL">Dizel</MenuItem>
                            <MenuItem value="GAS">LPG</MenuItem>
                            <MenuItem value="ELECTRIC">Elektrik</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Araç Toplam KM"
                            fullWidth
                            type="number"
                            inputProps={
                                { readOnly: true, }
                            }
                            value={editVehicleForm?.totalDistanceKm}
                        /*  onChange={(e) => { setEditVehicleForm({ ...editVehicleForm, totalDistanceKm: e.target.value }) }} */
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Bakım KM"
                            fullWidth
                            type="number"
                            value={editVehicleForm?.maintenanceDistance}
                            onChange={(e) => { setEditVehicleForm({ ...editVehicleForm, maintenanceDistance: e.target.valueAsNumber }) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Rölantı Alarm Saniye"
                            fullWidth
                            required={true}
                            type="number"
                            value={editVehicleForm?.idleAlarmSecond}
                            onChange={(e) => { setEditVehicleForm({ ...editVehicleForm, idleAlarmSecond: e.target.valueAsNumber }) }}
                        />
                    </Grid>

                    {/* added */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Muayene Başlama Tarihi"
                            fullWidth
                            type="date"
                            value={inspectionGivenDate}
                            onChange={(e) => setInspectionGivenDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    {/* added */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Muayene Geçerlilik Tarihi"
                            fullWidth
                            type="date"
                            value={inspectionExpireDate}
                            onChange={(e) => setInspectionExpireDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>



                    {/* added */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Sigorta Başlama Tarihi"
                            fullWidth
                            type="date"
                            value={mandatoryInsuranceStartDate}
                            onChange={(e) => setMandatoryInsuranceStartDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    {/* added */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Sigorta Geçerlilik Tarihi"
                            fullWidth
                            type="date"
                            value={mandatoryInsuranceExpireDate}
                            onChange={(e) => setMandatoryInsuranceExpireDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>


                    {/* added */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Kasko Başlama Tarihi"
                            fullWidth
                            type="date"
                            value={comprehensiveInsuranceStartDate}
                            onChange={(e) => setComprehensiveInsuranceStartDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    {/* added */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Kasko Geçerlilik Tarihi"
                            fullWidth
                            type="date"
                            value={comprehensiveInsuranceExpireDate}
                            onChange={(e) => setComprehensiveInsuranceExpireDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>


                    {/* added */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Lastik Montaj Tarihi"
                            fullWidth
                            type="date"
                            value={tireInstallationDate}
                            onChange={(e) => setTireInstallationDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    {/* added */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            label="Lastik Değişim KM"
                            fullWidth
                            type="number"
                            value={tireReplacementDistance}
                            onChange={(e) => setTireReplacementDistance(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            fullWidth
                            options={safeZones}
                            value={safeZones.find(v => v.id === editVehicleForm?.safeZone?.id)}
                            onChange={(e, value) => {
                                setEditVehicleForm({ ...editVehicleForm, safeZone: value })
                                /* setEditSafeZoneId(value.id) */
                            }}
                            getOptionLabel={(option) => option.safeZoneName}
                            renderInput={(params) => <TextField {...params} label="Güvenli Bölge" fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
                        }}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
                                <img src="/assets/immobilizer.svg" alt="Logo" />
                                <Typography sx={{ margin: theme.spacing(1) }} variant="p" >
                                    Immobilizer
                                </Typography>
                            </Box>
                            <Switch
                                checked={immobilizer}
                                onChange={async () => {
                                    try {
                                        let res = await setLockRequest({ id: editVehicleForm?.id, value: !immobilizer })
                                        if (res) {
                                            setImmobilizer(!immobilizer)
                                            if (immobilizer === true) {
                                                enqueueSnackbar('Immobilizer Kapatıldı', { variant: "success" });
                                                return
                                            }
                                            else {
                                                enqueueSnackbar('Immobilizer Açıldı', { variant: "success" });
                                                return
                                            }
                                        }
                                    } catch (error) {
                                        enqueueSnackbar('Bir Hata Oluştu', { variant: "error" });
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            fullWidth
                            label="Lisans Tarihi"
                            value={editVehicleForm?.licenseDate}
                            onChange={(newValue) => {
                                setEditVehicleForm({ ...editVehicleForm, licenseDate: newValue })
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                </Grid>
            </CustomDialog>
        </Box>

    )

}

export default Page